import { Component, OnInit } from '@angular/core';
import { EnjazReportService } from '../../services/enjaz-report.service';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-showenjaz',
  templateUrl: './showenjaz.page.html',
  styleUrls: ['./showenjaz.page.scss'],
})
export class ShowenjazPage implements OnInit {

  currentLang;
  constructor(private authService: AuthService,
    private enjazReportService:EnjazReportService,
    private modalController:ModalController,
    private navParams: NavParams,
    private translate: TranslateService) { 
      this.currentLang = translate.currentLang;
      if(!this.currentLang){
        this.currentLang = translate.getDefaultLang();
      }
    }

  currentUser = this.authService.getUser();
  enjazList;
  data;
  employeeEnjazDetalis:string;
  noData=false;
  ionViewWillEnter2() {
    this.enjazReportService.getAllEnjazReport().subscribe(res=>{
      if(Object.keys(res).length > 0){
        this.enjazList=res;
        this.noData=false;
      }else{
        this.noData=true;
      }
    })
  }

  dismissModal(){
    this.modalController.dismiss(null, 'cancel');
  }

  ngOnInit() {
    this.data = this.navParams.get('data');
    this.enjazReportService.getEnjazDetails(this.data).subscribe(res=>{
      if(Object.keys(res).length > 0){
        this.employeeEnjazDetalis = res[0].user.userName;
        this.enjazList=res;
        this.noData=false;
      }else{
        this.noData=true;
      }
    });
  }
}
