import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
  DeliveredNotifications,
} from '@capacitor/push-notifications';
import { FcmService } from '../../services/fcm.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.page.html',
  styleUrls: ['./notifications.page.scss'],
})
export class NotificationsPage implements OnInit {

  constructor(private modalController: ModalController,private notification: FcmService) { }
  notifcaitionMessages: any[];

  ngOnInit() {
    this.notification.getNotification().subscribe(res=>{       
       if(res && res.length != 0){
        this.notifcaitionMessages = res;
        this.notification.changeMessagesStauts().subscribe(res=>{
          if (Capacitor.getPlatform() !== 'web') {
            this.resetBadgeCount();
          }
        });
        }
     })
  }

  dismissModal(){
    this.modalController.dismiss(null, 'cancel');
  }
  resetBadgeCount() {
    PushNotifications.removeAllDeliveredNotifications();
  }

  deleteNotificationMessage(id: number){
    this.notification.deleteNotification(id).subscribe(res=>{
      if(res){
        const index = this.notifcaitionMessages.findIndex(ids => ids.id == id)
        this.notifcaitionMessages.splice(index, 1);
        if(this.notifcaitionMessages.length == 0){

        }
      }
    });
  }
}
