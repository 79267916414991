import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { IonicModule, NavParams} from '@ionic/angular';

import { AddTaskPageRoutingModule } from './add-task-routing.module';

import { AddTaskPage } from './add-task.page';

import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';

import { IonicSelectableModule } from 'ionic-selectable';

import { TranslateModule} from '@ngx-translate/core';

import { PopoverComponent } from '../../components/popover/popover.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    AddTaskPageRoutingModule,
    MDBBootstrapModulesPro.forRoot(),
    TranslateModule,
    IonicSelectableModule
  ],
  declarations: [AddTaskPage,PopoverComponent],
  providers: [NavParams],
})
export class AddTaskPageModule {}
